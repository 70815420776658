import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Clessidra from "../../../../src/assets/clessidra.svg";
import TableOfContents from "../../../../src/components/TableOfContents";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  Clessidra,
  TableOfContents,
  React
};