module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/Users/giammarcogaudenzi/Desktop/DEVELOP/Gatsby/FidoFattura/src/components/layout.jsx"},"rehypePlugins":[null],"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/giammarcogaudenzi/Desktop/DEVELOP/Gatsby/FidoFattura","commonmark":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
