import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

const TableOfContents = ({ headings }) => {
  // console.log(headings)
  const [slugged, setSlugged] = useState([])
  useEffect(() => {
    let slugged_headings = []
    headings.forEach(heading => {
      slugged_headings.push(heading.value.replaceAll(" ", "-").toLowerCase())
    })

    setSlugged(slugged_headings)
  }, [headings])
  // console.log(slugged)
  return (
    <div className="p-4 bg-[#DCFCF3] dark:bg-gray-800 mb-8">
      <h2 className="font-bold text-3xl pb-3 dark:text-[orange]">Indice</h2>
      <ul>
        {slugged.map(heading => {
          return (
            <li key={heading}>
              <Link
                to={`#${heading}`}
                activeStyle={{ color: "orange" }}
                partiallyActive={true}
              >
                {heading}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default TableOfContents
